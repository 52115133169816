// Generated by Framer (ee31e22)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const cycleOrder = ["XzbNzJhdv", "auqSarqrc"];

const serializationHash = "framer-Hm0Qe"

const variantClassNames = {auqSarqrc: "framer-v-15dyouk", XzbNzJhdv: "framer-v-atczi5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Dark: "XzbNzJhdv", Light: "auqSarqrc"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "XzbNzJhdv"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "XzbNzJhdv", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-atczi5", className, classNames)} data-framer-name={"Dark"} layoutDependency={layoutDependency} layoutId={"XzbNzJhdv"} ref={refBinding} style={{...style}} {...addPropertyOverrides({auqSarqrc: {"data-framer-name": "Light"}}, baseVariant, gestureVariant)}><Link href={{webPageId: "augiA20Il"}} motionChild nodeId={"hmF23Dt8G"} scopeId={"wQpeNPJao"}><SVG as={"a"} className={"framer-1gi9vyq framer-1hg3gnl"} data-framer-name={"Framer-svgrepo-com"} fill={"var(--token-1e413a12-0cc5-43ac-8f06-e1cf6beb50f2, rgb(255, 84, 0))"} intrinsicHeight={800} intrinsicWidth={800} layoutDependency={layoutDependency} layoutId={"hmF23Dt8G"} svg={"<svg width=\"800\" height=\"800\" viewBox=\"0 0 24 24\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M4 0h16v8h-8zm0 8h8l8 8H4zm0 8h8v8z\"/></svg>"} withExternalLayout/></Link><RichText __fromCanvasComponent children={<React.Fragment><motion.h6 style={{"--font-selector": "RlM7U2F0b3NoaS1ib2xk", "--framer-font-family": "\"Satoshi\", \"Satoshi Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-font-weight": "700", "--framer-letter-spacing": "-0.05em", "--framer-line-height": "1.1em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-1w1cjl5, var(--token-86bec35a-703b-4281-a484-f2089fb7964d, rgb(32, 32, 32)))"}}>CoursePro</motion.h6></React.Fragment>} className={"framer-jq2y60"} fonts={["FS;Satoshi-bold"]} layoutDependency={layoutDependency} layoutId={"tDBqPDGJM"} style={{"--extracted-1w1cjl5": "var(--token-86bec35a-703b-4281-a484-f2089fb7964d, rgb(32, 32, 32))"}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Hm0Qe.framer-1hg3gnl, .framer-Hm0Qe .framer-1hg3gnl { display: block; }", ".framer-Hm0Qe.framer-atczi5 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 5px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: min-content; }", ".framer-Hm0Qe .framer-1gi9vyq { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 24px); position: relative; text-decoration: none; width: 24px; }", ".framer-Hm0Qe .framer-jq2y60 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Hm0Qe.framer-atczi5 { gap: 0px; } .framer-Hm0Qe.framer-atczi5 > * { margin: 0px; margin-left: calc(5px / 2); margin-right: calc(5px / 2); } .framer-Hm0Qe.framer-atczi5 > :first-child { margin-left: 0px; } .framer-Hm0Qe.framer-atczi5 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 110
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"auqSarqrc":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 * @framerColorSyntax true
 */
const FramerwQpeNPJao: React.ComponentType<Props> = withCSS(Component, css, "framer-Hm0Qe") as typeof Component;
export default FramerwQpeNPJao;

FramerwQpeNPJao.displayName = "Elements / Logo 2";

FramerwQpeNPJao.defaultProps = {height: 24, width: 110};

addPropertyControls(FramerwQpeNPJao, {variant: {options: ["XzbNzJhdv", "auqSarqrc"], optionTitles: ["Dark", "Light"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerwQpeNPJao, [{explicitInter: true, fonts: [{family: "Satoshi", source: "fontshare", style: "normal", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/LAFFD4SDUCDVQEXFPDC7C53EQ4ZELWQI/PXCT3G6LO6ICM5I3NTYENYPWJAECAWDD/GHM6WVH6MILNYOOCXHXB5GTSGNTMGXZR.woff2", weight: "700"}]}], {supportsExplicitInterCodegen: true})